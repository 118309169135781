<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const loading = ref(false)
const authStore = useAuthStore()
const credentials = reactive({
  username: '',
  password: '',
})

async function handleSignIn() {
  try {
    await authStore.login(credentials)
    useToastification().success(t('login.login_success_description'))
  }
  catch (e) {
    useToastification().error(t('login.login_failed_description'))
  }
}
</script>

<template>
  <div class="flex-center my-12 flex w-full flex-col gap-12">
    <div class="flex w-full flex-col gap-2 rounded-xl border p-4 text-center">
      <form @submit.prevent="handleSignIn">
        <h2 class="w-full py-4 text-2xl font-bold">
          {{ t('login.login') }}
        </h2>

        <div class="min-w-xs flex flex-col gap-4">
          <PrimeInputText
            v-model="credentials.username"
            type="email"
            input-class="w-full"
            :placeholder="t('login.email')"
          />

          <PrimePassword
            v-model="credentials.password"
            :feedback="false"
            toggle-mask
            input-class="w-full"
            :placeholder="t('login.password')"
          />

          <PrimeButton
            :disabled="!credentials.username || !credentials.password"
            :loading="loading"
            :label="t('login.login')"
            type="submit"
          />

          <NuxtLinkLocale to="/password-reset">
            {{ t('login.forgot_password') }}
          </NuxtLinkLocale>
        </div>
      </form>
    </div>

    <div class="flex w-full flex-col gap-4 rounded-xl border p-4 text-center">
      <h2 class="w-full py-4 text-2xl font-bold">
        {{ t('login.no_account') }}
      </h2>

      <NuxtLinkLocale to="/register">
        <PrimeButton
          class="w-full"
          :label="t('login.register')"
        />
      </NuxtLinkLocale>

      <NuxtLinkLocale to="/register-as-dealer">
        {{ t('register.as_dealer') }}
      </NuxtLinkLocale>
    </div>
  </div>
</template>
